













import Faq from '@/components/faq/FAQ.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: { Faq }
})
export default class FAQView extends Vue {
  get manualDownloadLabel (): string {
    return `${this.$t('commons.manual_download')}`
  }
}
